import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import i18n from '../../../i18n';
import { smartMeterPhasesDataSelector } from '../../../redux-store/selectors/loadManagementDashboard';

import { getPlotLines, chartGridColor, phase1Color, phase2Color, phase3Color, ticksColor, getPlotBands, generalChartOptions } from '../constants';

import '../index.scss';

const labelColors = [phase1Color, phase2Color, phase3Color];

const LoadManagementBarChart = (props) => {
  const { houseFuse, yAxisMax, yAxisMin } = props;
  const currentValues = useSelector(smartMeterPhasesDataSelector);

  const plotLines = getPlotLines(houseFuse, false);

  const plotBands = getPlotBands(houseFuse, [currentValues?.currentL1, currentValues?.currentL2, currentValues?.currentL3]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...generalChartOptions,
        chart: {
          ...generalChartOptions.chart,
          marginBottom: 50
        },
        tooltip: {
          ...generalChartOptions.tooltip,
          // remove : from tooltip
          pointFormatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return `<span style="color:${this.color}">\u25CF</span> <b>${this.y} A</b><br/>`;
          }
        },
        xAxis: {
          tickAmount: 3,
          lineColor: 'rgba(27, 27, 26, 0.10)',
          categories: [i18n.t('phase1'), i18n.t('phase2'), i18n.t('phase3')],
          labels: {
            style: {
              color: ticksColor,
              fontSize: '16px',
              fontWeight: '500'
            },
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return `<span style="color:${labelColors[this.pos]}">${this.value}</span>`;
            },
            tickWidth: 0,
            lineWidth: 0
          }
        },

        yAxis: {
          plotBands,
          plotLines,
          gridLineColor: chartGridColor,
          lineColor: 'rgba(27, 27, 26, 0.10)',
          gridLineWidth: 1,
          labels: {
            style: {
              color: ticksColor,
              fontSize: '16px',
              fontWeight: '400'
            },
            formatter() {
              // eslint-disable-next-line react/no-this-in-sfc
              return `${this.value} A`;
            }
          },
          max: houseFuse >= yAxisMax ? Math.ceil((houseFuse + 10) / 10) * 10 : yAxisMax,
          min: houseFuse * -1 <= yAxisMin ? Math.ceil((houseFuse + 10) / 10) * -10 : yAxisMin,
          tickWidth: 0,
          lineWidth: 0,
          title: { text: '' }
        },
        plotOptions: {
          column: {
            pointPadding: -0.2,
            width: '100%',
            borderWidth: 0,
            borderRadius: 0
          }
        },
        series: [
          {
            type: 'column',
            name: '',
            data: [currentValues?.currentL1, currentValues?.currentL2, currentValues?.currentL3],
            colors: [phase1Color, phase2Color, phase3Color],
            colorByPoint: true
          }
        ]
      }}
      containerProps={{ className: 'bar-chart' }}
    />
  );
};

LoadManagementBarChart.propTypes = {
  houseFuse: PropTypes.number.isRequired,
  yAxisMax: PropTypes.number.isRequired,
  yAxisMin: PropTypes.number.isRequired
};

export default LoadManagementBarChart;
