import React from 'react';
import PropTypes from 'prop-types';
import ReactFlagsSelect from 'react-flags-select';

import { countries, countryLabelsBack, countryLabels, countryLabelsBackGerman, countryLabelsGerman } from './constants';
import i18n from '../../../i18n';

import './index.scss';

/**
 * Renders languageSelector for redux form
 * @memberof module:ReduxFormFields
 */
export const CountrySelector = ({
  input,
  id,
  label,
  buttonClassName,
  meta: { touched, error },
  ...custom
}) => {
  const currentLanguage = i18n.language;
  const isDeutsch = currentLanguage === 'deutsch_de';
  const getSelectedCountry = () => {
    if (countryLabelsBackGerman[input.value]) {
      return countryLabelsBackGerman[input.value];
    }
    if (countryLabelsBack[input.value]) {
      return countryLabelsBack[input.value];
    }

    return '';
  };

  return (
    <>
      <ReactFlagsSelect
        {...input}
        {...custom}
        rfsKey={id}
        selectButtonClassName={`${buttonClassName} ${custom.disabled ? `${buttonClassName}_disabled` : ''}`}
        onSelect={(country) => input.onChange(countryLabels[country])}
        countries={countries}
        customLabels={isDeutsch ? countryLabelsGerman : countryLabels}
        selected={getSelectedCountry()}
        selectedSize={18}
        placeholder={`${i18n.t('select')}`}
        searchable
        searchPlaceholder={`${i18n.t('search')}`}
      />
      {touched && error && (
      <div className="inputCustomWarning">
        {i18n.t(error)}
      </div>
      )}
    </>
  );
};

CountrySelector.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  buttonClassName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired
};
