import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../i18n';
import heatpumpIcon from '../../../../../assets/images/svg/ico.heat_pump.svg';
import greenArrowIcon from '../../../../../assets/images/svg/green-arrow.svg';
import grayArrowIcon from '../../../../../assets/images/svg/gray-arrow.svg';
import redArrowIcon from '../../../../../assets/images/svg/red-arrow.svg';

const renderHeatingAdjustment = {
  1: grayArrowIcon,
  2: redArrowIcon,
  3: greenArrowIcon
};

const operationStateValue = {
  1: 'heatPumpOperationModeStandby',
  2: 'heatPumpOperationModeHeating',
  3: 'heatPumpOperationModeWarmWater',
  4: 'heatPumpOperationModeFailure',
  5: 'heatPumpOperationModePartialerror',
  6: 'heatPumpOperationModeCooling',
  7: 'heatPumpOperationModeEvu',
  8: 'heatPumpOperationModeDefrosting'
};

const HeatpumpTooltip = (props) => {
  const {
    heatingAdjustment,
    onTimeToday,
    tempInside,
    tempOutside,
    warmWaterTemp,
    currentOperationState,
    energyToday,
    convertedCurrentPower,
    heatPumpMode
  } = props;

  return (
    <>
      <img src={heatpumpIcon} alt="Heatpump Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        {Number.isFinite(heatingAdjustment) && (
          <span>{i18n.t('heatPumpOperationModeHeating')}</span>
        )}
        {Number.isFinite(onTimeToday) && <span>{i18n.t('heatPumpOnTimeToday')}</span>}
        {Number.isFinite(tempInside) && <span>{i18n.t('heatPumpTempInside')}</span>}
        {Number.isFinite(tempOutside) && <span>{i18n.t('heatPumpTempOutside')}</span>}
        {Number.isFinite(warmWaterTemp) && <span>{i18n.t('heatPumpWarmWaterTemp')}</span>}
        {Number.isFinite(currentOperationState) && <span>{i18n.t('heatPumpOperationMode')}</span>}
        {Number.isFinite(energyToday) && <span>{i18n.t('heatPumpEnergyToday')}</span>}
        {Number.isFinite(heatPumpMode) && <span>{i18n.t('heatPumpModeLabel')}</span>}
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        {Number.isFinite(heatingAdjustment) && (
          <span>
            <img
              src={renderHeatingAdjustment[heatingAdjustment]}
              alt="Arrow Icon"
            />
          </span>
        )}
        {Number.isFinite(onTimeToday) && (
          <span>
            {onTimeToday.toFixed(2).replace('.', ',')}
            {i18n.t('h')}
          </span>
        )}
        {Number.isFinite(tempInside) && (
          <span>
            {tempInside.toFixed(2).replace('.', ',')}
            °C
          </span>
        )}
        {Number.isFinite(tempOutside) && (
          <span>
            {tempOutside.toFixed(2).replace('.', ',')}
            °C
          </span>
        )}
        {Number.isFinite(warmWaterTemp) && (
          <span>
            {warmWaterTemp.toFixed(2).replace('.', ',')}
            °C
          </span>
        )}
        {Number.isFinite(currentOperationState) && (
          <span>{i18n.t(operationStateValue[currentOperationState])}</span>
        )}
        {Number.isFinite(energyToday) && (
          <span>
            {energyToday.toFixed(2).replace('.', ',')}
            {i18n.t('kwh')}
          </span>
        )}
        {Number.isFinite(heatPumpMode) && (
          <span>
            {heatPumpMode ? i18n.t('noControl') : i18n.t('optimizationOn')}
          </span>
        )}
      </div>
    </>
  );
};

HeatpumpTooltip.propTypes = {
  heatingAdjustment: PropTypes.number,
  onTimeToday: PropTypes.number,
  tempInside: PropTypes.number,
  tempOutside: PropTypes.number,
  warmWaterTemp: PropTypes.number,
  currentOperationState: PropTypes.number,
  energyToday: PropTypes.number,
  heatPumpMode: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired
};

export default HeatpumpTooltip;
