/**
 * Array of languages to select in guests pages
 * @ignore
 */
export const options = [
  {
    value: 'english',
    label: 'English'
  },
  {
    value: 'deutsch_de',
    label: 'Deutsch (Deutschland)'
  }
];
