import React from 'react';

/**
 * Renders values in Wh format
 * @memberof module:Dashboard
 * @param {number} value Value to render
 * @param {number} digits Amount of decimal digits
 * @param {boolean} alwaysKilo Always show in kilo
 */
const RenderWh = (value, digits, alwaysKilo) => {
  let digits_;
  let unit = 'Wh';
  let value_ = value;
  if (alwaysKilo || value_ > 1000) {
    value_ /= 1000;
    unit = 'kWh';
  }
  if (typeof digits !== 'undefined') {
    digits_ = digits;
  } else {
    digits_ = value_ > 99 ? 0 : 2;
  }
  value_ = Number.isNaN(value_)
    ? 0
    : Math.round(value_ * (10 ** digits_)) / (10 ** digits_);
  return (
    <>
      {String(value_).replace('.', ',')}
      <span className="det-energy-stat-kwh">{unit}</span>
    </>
  );
};

export default RenderWh;
