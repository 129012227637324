import React from 'react';
import { useSelector } from 'react-redux';

import {
  smartMeterPhasesDataSelector,
  getLoadManagementDashboardUserHouseFuseSelector
} from '../../../redux-store/selectors/loadManagementDashboard';
import i18n from '../../../i18n';

const LoadManagementCurrentValues = () => {
  const currentValues = useSelector(smartMeterPhasesDataSelector);
  const houseFuse = useSelector(getLoadManagementDashboardUserHouseFuseSelector);

  return (
    <div className="loadManagementCurrentValues-container">
      <div>
        <span className="label">{i18n.t('houseFuseLimit')}</span>
        <div className="houseFuse">
          <span className="value phase-general">{String(houseFuse).replace('.', ',')}</span>
          <span className="value-a">A</span>
        </div>
      </div>
      <div>
        <span className="label">{i18n.t('phase1')}</span>
        <div className="phase1">
          {currentValues?.currentL1 ? (
            <>
              <span className="value phase-general">{String(currentValues?.currentL1).replace('.', ',')}</span>
              <span className="value-a">A</span>
            </>
          ) : (
            <span className="value-minus">-</span>
          )}
        </div>
      </div>
      <div>
        <span className="label">{i18n.t('phase2')}</span>
        <div className="phase2">
          {currentValues?.currentL2 ? (
            <>
              <span className="value phase-general">{String(currentValues?.currentL2).replace('.', ',')}</span>
              <span className="value-a">A</span>
            </>
          ) : (
            <span className="value-minus">-</span>
          )}
        </div>
      </div>
      <div>
        <span className="label">{i18n.t('phase3')}</span>
        <div className="phase3">
          {currentValues?.currentL3 ? (
            <>
              <span className="value phase-general">{String(currentValues?.currentL3).replace('.', ',')}</span>
              <span className="value-a">A</span>
            </>
          ) : (
            <span className="value-minus">-</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadManagementCurrentValues;
