import numeral from 'numeral';

/**
 * Divides number values by a symbol.
 * Example 1000 becomes 1,000, and -1000000 becomes -1,000,000.
 * @function divideNumberBySymbol
 * @memberof module:Dashboard
 * @param {number} value Value to divide
 * @param {string} [divider=','] Divider symbol
 * @returns {string|null}
 */
const divideNumberBySymbol = (value, divider = ',') => {
  if (!Number.isFinite(value)) {
    return null;
  }

  const formattedValue = numeral(value).format('0,000');

  return divider === ','
    ? formattedValue
    : formattedValue.replace(',', divider);
};

/**
 * Formats realtime data values at top of dashboard
 * @memberof module:Dashboard
 * @param {*} value Value to format
 * @returns {string|null}
 */
const formatTopValue = (value) => (
  divideNumberBySymbol(Math.round(value), '.')
);

export default formatTopValue;
