import React from 'react';
import { TextareaAutosize } from '@mui/material';

import i18n from '../../../../../i18n';
import { APP_ID, EZEE_APP_ID, CKW_APP_ID } from '../../../../../config';

const allowedRoles = [
  'root',
  'solar_admin',
  'property_management',
  'viewer',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
  'pv_installer_employee_read_install',
  'oem',
  'oem_employee',
  'oem_employee_read_only',
  'end_user'
];

const additionalRestrictionsToShowEditSubscription = [EZEE_APP_ID, CKW_APP_ID].includes(APP_ID) ? ['oem'] : [];

export const getColumns = ({ user = {}, myRoleType, redirectToPaymentHandler, isReadOnlyPermission }) => {
  const { gateway = {} } = user;

  const isShowUserSubscriptionEditBtn = ['solar_admin', 'root', 'pv_installer', 'end_user', 'property_management', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', ...additionalRestrictionsToShowEditSubscription].includes(myRoleType) && !isReadOnlyPermission;

  return [
    [{
      title: 'fName',
      isAllowed: allowedRoles.includes(myRoleType),
      // isAllowed: roles.end_user,
      Value: () => (
        <p>
          {user.first_name || ''}
          {' '}
          {user.last_name || ''}
        </p>
      )
    },
    {
      title: 'BV/CO-Number',
      isAllowed: myRoleType !== 'end_user',
      Value: () => <p>{user.plant?.length > 16 ? `${user.plant?.slice(0, 16)}...` : user.plant}</p>
    },
    {
      title: 'SMID',
      tooltip: 'SMIDTolltip',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{gateway.sm_id}</p>
    },
    {
      title: 'userSettingNotesLabel',
      wrapperClasses: 'align-self-start',
      tooltip: 'userSettingNotesTooltip',
      isAllowed: myRoleType !== 'end_user',
      Value: () => (
        user.note && <TextareaAutosize disabled defaultValue={user.note} />
      )
    }],
    [{
      title: 'subscription',
      isAllowed: user.license?.type === 'payment',
      Value: () => (
        <span className="subscription-block-info">
          <span className={`plan-type code-${user.license?.licenseCode || 'common'}`}>
            {user.license?.name || ''}
            {user.helionAppVersion === 2 ? ' V2' : ''}
          </span>
          {isShowUserSubscriptionEditBtn && (
            <span className="dropdown">
              <button
                type="button"
                onClick={redirectToPaymentHandler}
                className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill subsection-icon"
              >
                <i className="la la-pencil" />
              </button>
            </span>
          )}
        </span>
      )

    },
    {
      title: 'IP',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{gateway.ip}</p>
    },
    {
      title: 'MAC',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{gateway?.mac}</p>
    },
    {
      title: 'status',
      isAllowed: allowedRoles.includes(myRoleType),
      Value: () => <p>{user.status}</p>
    }],
    [{
      title: 'signal',
      value: user.signal,
      isAllowed: allowedRoles.includes(myRoleType),
      isConnected: gateway.signal === 'connected',
      Value: () => (
        <span className="m--font-bold signal m--font-success">
          {gateway.signal === 'connected' ? (
            <>
              <span className="m-badge m-badge--success m-badge--dot" />
              &nbsp;
              <span className="m--font-bold signal m--font-success">{i18n.t(gateway.signal)}</span>
            </>
          ) : (
            <>
              <span className="m-badge m-badge--danger m-badge--dot" />
              &nbsp;
              <span className="m--font-bold signal m--font-danger">{i18n.t(gateway.signal)}</span>
            </>
          )}
        </span>
      )
    },
    {
      title: 'error',
      value: user.signal,
      isAllowed: [0, 1].includes(gateway.sensorErrorActivity) || gateway.hasErrors,
      gatewayErrors: user?.gateway?.errors || [],
      Value: () => (
        <div className="m-datatable__cell">
          <span className={`error_status_yes ${gateway.hasErrors || gateway.sensorErrorActivity === 0 ? 'error' : 'warning'}`}>{i18n.t('yes')}</span>
        </div>
      )
    }]
  ];
};
