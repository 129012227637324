import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import v2xIcon from '../../../../../assets/images/svg/v2x.svg';

const V2XTooltip = (props) => {
  const { convertedCurrentPowerBattery, SOC, chargedForLast24Hours, dischargedForLast24Hours } = props;

  return (
    <>
      <img src={v2xIcon} alt="V2X Bidirectional charging Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        <span>{i18n.t('batterySoc')}</span>
        <span>{i18n.t('charged')}</span>
        <span>{i18n.t('discharged')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPowerBattery ? Number(convertedCurrentPowerBattery).toFixed(1).replace('.', ',') : convertedCurrentPowerBattery}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        <span>
          {SOC || defaultValue}
          %
        </span>
        <span>
          {chargedForLast24Hours ? chargedForLast24Hours.toFixed(1).replace('.', ',') : defaultValue}
          {i18n.t('kwh')}
        </span>
        <span>
          {dischargedForLast24Hours ? dischargedForLast24Hours.toFixed(1).replace('.', ',') : defaultValue}
          {i18n.t('kwh')}
        </span>
      </div>
    </>
  );
};

V2XTooltip.propTypes = {
  convertedCurrentPowerBattery: PropTypes.number.isRequired,
  SOC: PropTypes.number,
  chargedForLast24Hours: PropTypes.number,
  dischargedForLast24Hours: PropTypes.number
};

export default V2XTooltip;
