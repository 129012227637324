import view from './view.json';

const replace = {
  changeSMID: view.changeThermondoID,
  reqSMID: view.reqThermondoID,
  SMID: view.thermondoID,
  SMIDTolltip: view.thermondoIDTooltip,
  nameOrSmid: view.nameOrThermondoID,
  nameOrSmidPlaceholder: view.nameOrThermondoIdPlaceholder,
  smidLabel: view.thermondoIdLabel
};

export default replace;
