/**
 * Short id of language like its spelled in react-flags-select
 * @ignore
 */
export const countries = ['US', 'DE'];
export const thermondoCountriesCode = ['US', 'DE'];

/**
 * Language long id to short
 * @ignore
 */
export const countryLabelsBack = {
  English: 'US',
  'Deutsch (Schweiz)': 'CH',
  'Deutsch (Deutschland)': 'DE',
  Deutsch: 'CH',
  French: 'FR',
  Italian: 'IT',
  Danish: 'DK'
};

/**
 * Language short id to long
 * @ignore
 */
export const countryLabels = {
  US: 'English',
  DE: 'Deutsch (Deutschland)'
};

export const thermondoCountryLabels = {
  US: 'English',
  DE: 'Deutsch (Deutschland)'
};
