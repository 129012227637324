import React from 'react';
import PropTypes from 'prop-types';

import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';
import smartMeterIcon from '../../../../../assets/images/svg/energy-measurement.svg';

const SmartMeterTooltip = (props) => {
  const { currentPowerInvSm, energyDelivery, energyPurchase } = props;

  return (
    <>
      <img src={smartMeterIcon} alt="Smart Meter Icon" />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        {energyPurchase && <span>{i18n.t('energyImport')}</span>}
        {energyDelivery && <span>{i18n.t('export')}</span>}
      </div>
      <div className="sensorData-value">
        <span>
          {(Number.isFinite(currentPowerInvSm) && (currentPowerInvSm / 1000) % 1 !== 0 ? (currentPowerInvSm / 1000).toFixed(3).replace('.', ',') : (currentPowerInvSm / 1000)) || defaultValue}
          {i18n.t('kW')}
        </span>
        {energyPurchase && (
          <span>
            {energyPurchase}
            {i18n.t('Wh')}
          </span>
        )}
        {energyDelivery && (
          <span>
            {energyDelivery}
            {i18n.t('Wh')}
          </span>
        )}
      </div>
    </>
  );
};

SmartMeterTooltip.propTypes = {
  currentPowerInvSm: PropTypes.number,
  energyDelivery: PropTypes.number,
  energyPurchase: PropTypes.number
};

export default SmartMeterTooltip;
