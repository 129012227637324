import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openModalWindow, UPGRADE_TO_UNLOCK_POPUP } from '../../../containers/ModalWindow/slice';
import i18n from '../../../i18n';

const CustomSelectOption = (props) => {
  const dispatch = useDispatch();
  const openUpgradePopup = () => dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
  const { innerProps, isDisabled, data } = props;

  return (
    <div
      {...innerProps}
      className="react-select__option"
      style={{ opacity: isDisabled ? 0.55 : 1 }}
      onClick={data.isOpenUpgradePopup ? openUpgradePopup : innerProps.onClick}
    >
      {i18n.exists(data.label) ? i18n.t(data.label) : data.label}
    </div>
  );
};

CustomSelectOption.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isDisabled: PropTypes.bool
};

export default CustomSelectOption;
