import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import logoWhite from './logos/logo-white.png';
import solarLogo from './logos/logo.png';

import './index.scss';

/**
 * Renders Solar Manager Logo for all pages
 */
const Logo = (props) => {
  const logo = props.isWhiteLogo ? logoWhite : solarLogo;

  return (
    <div className="m-login__log">
      <Link to="/">
        <img
          src={logo}
          alt="logo_solar_manager"
          className="topBarLogo"
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  isWhiteLogo: PropTypes.bool
};

Logo.defaultProps = {
  isWhiteLogo: false
};

export default Logo;
