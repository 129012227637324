import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import CustomSelectOption from './CustomSelectOption';
import i18n from '../../../i18n';

/**
 * Pushes new value to options
 * @param input {Object}
 * @param custom {Object}
 */
const pushNewOption = (input, custom) => {
  const { options } = custom;
  const { value } = input;
  if (Array.isArray(options)
    && typeof value !== 'undefined'
    && value !== null
    && value !== ''
    && typeof options.find((option) => (option.value === value)) === 'undefined') {
    options.unshift({ value, label: value });
  }
};

/**
 * Renders selector for redux form
 * @memberof module:ReduxFormFields
 */
export const CustomSelect = ({
  creatable,
  maxLength,
  newOptionCreatedCB,
  input,
  meta: { touched, error },
  disabled,
  defaultValue,
  ...custom
}) => {
  const [key, setKey] = useState(0);
  const props = {
    clearable: false,
    ...input,
    ...custom,
    value: custom.options.map(({ value, label }) => ({ value, label: i18n.exists(label) ? i18n.t(label) : label })).find((option) => (option.value === input.value)) || null,
    onInputChange: (value) => (Number.isSafeInteger(maxLength) ? value.substring(0, maxLength) : value),
    onChange: (selected) => {
      input.onChange(selected ? selected.value : null);
    },
    onBlur: (event) => event.preventDefault(),
    isDisabled: disabled,
    className: disabled ? 'react-select-container disabeldSelect' : 'react-select-container',
    classNamePrefix: 'react-select',
    isOptionDisabled: (option) => option.disabled,
    components: { Option: CustomSelectOption }
  };
  if (creatable) {
    const onNewOptionClick = (prop) => {
      setTimeout(() => {
        // TODO: refactor this
        input.onChange(prop);
        setKey(key + 1);
        if (typeof newOptionCreatedCB === 'function') newOptionCreatedCB(prop);
      }, 0);
      input.onChange(prop);
    };

    pushNewOption(input, custom);

    return (
      <>
        <Creatable
          {...props}
          isClearable
          onCreateOption={onNewOptionClick}
          key={key}
        />
        {touched && error && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <Select {...props} />
      {touched && error && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
    </>
  );
};

CustomSelect.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  creatable: PropTypes.bool,
  newOptionCreatedCB: PropTypes.func,
  meta: PropTypes.instanceOf(Object).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.instanceOf(Object)
  ]),
  maxLength: PropTypes.number,
  disabled: PropTypes.bool
};
