export const DEFAULT_FONT = 'Open Sans';

export const INSTALLATION_ONGOING_COLOR = '#3998F9';
export const NOT_CONNECTED_STATUS_COLOR = '#DB422D';
export const CONNECTED_COLOR = '#4CCD37';

export const OK_COLOR = '#1BBC9B';
export const WARNING_COLOR = '#FF9E22';
export const ERROR_COLOR = '#F66400';
export const NOT_CONNECTED_MONITORING_COLOR = '#DB422D';

export const PIE_CHART_COLORS = {
  countColor: '#1B1B1A',
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#1B1B1AAB',
  itemStyleColor: '#1B1B1A',
  titleColor: '#1B1B1A',
  bgColor: '#fff'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#c6c8d2',
  itemHoverStyle: '#1B1B1A',
  itemStyleColor: '#1B1B1AAB',
  titleColor: '#1B1B1A',
  bgColor: '#fff',
  OVERALL_ACTIVE_COLOR: '#DB422D',
  ONE_YEAR_ACTIVE_COLOR: '#3998F9',
  ONE_MONTH_ACTIVE_COLOR: '#4CCD37',
  OVERALL_EMPTY_COLOR: '#db422d1a',
  ONE_YEAR_EMPTY_COLOR: '#3998F91a',
  ONE_MONTH_EMPTY_COLOR: '#4CCD371a'
};

export const tooltip = {
  backgroundColor: '#fff',
  style: {
    color: '#1B1B1A',
    fontSize: '16px'
  }
};
