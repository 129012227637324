const objectFlip = (obj) => Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const countries = {
  AL: 'Albania',
  AD: 'Andorra',
  AT: 'Austria',
  BE: 'Belgium',
  BA: 'Bosnia and Herzegovina',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  VA: 'Holy See (Vatican City State)',
  HU: 'Hungary',
  IS: 'Iceland',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LI: 'Liechtenstein',
  LU: 'Luxembourg',
  MT: 'Malta',
  MD: 'Moldova',
  MC: 'Monaco',
  ME: 'Montenegro',
  NL: 'Netherlands',
  MK: 'North Macedonia',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SM: 'San Marino',
  RS: 'Serbia',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  UA: 'Ukraine',
  GB: 'United Kingdom'
};

export const countriesGerman = {
  AL: 'Albanien',
  AD: 'Andorra',
  AT: 'Österreich',
  BE: 'Belgien',
  BA: 'Bosnien und Herzegowina',
  BG: 'Bulgarien',
  HR: 'Kroatien',
  CZ: 'Tschechien',
  DK: 'Dänemark',
  EE: 'Estland',
  FI: 'Finnland',
  FR: 'Frankreich',
  DE: 'Deutschland',
  GR: 'Griechenland',
  VA: 'Heiliger Stuhl',
  HU: 'Ungarn',
  IS: 'Island',
  IE: 'Irland',
  IT: 'Italien',
  LV: 'Lettland',
  LT: 'Litauen',
  LI: 'Liechtenstein',
  LU: 'Luxemburg',
  MT: 'Malta',
  MD: 'Moldawien',
  MC: 'Monaco',
  ME: 'Montenegro',
  NL: 'Niederlande',
  MK: 'Nordmazedonien',
  NO: 'Norwegen',
  PL: 'Polen',
  PT: 'Portugal',
  RO: 'Rumänien',
  SM: 'San Marino',
  RS: 'Serbien',
  SK: 'Slowakei',
  SI: 'Slowenien',
  ES: 'Spanien',
  SE: 'Schweden',
  CH: 'Schweiz',
  UA: 'Ukraine',
  GB: 'Vereinigtes Königreich'
};

export const countryCodes = {
  Albania: '+355',
  Andorra: '+376',
  Austria: '+43',
  Belgium: '+32',
  'Bosnia and Herzegovina': '+387',
  Bulgaria: '+359',
  Croatia: '+385',
  'Czech Republic': '+420',
  Denmark: '+45',
  Estonia: '+372',
  Finland: '+358',
  France: '+33',
  Germany: '+49',
  Greece: '+30',
  'Holy See': '+3906698',
  Hungary: '+36',
  Iceland: '+354',
  Ireland: '+353',
  Italy: '+39',
  Latvia: '+371',
  Lithuania: '+370',
  Liechtenstein: '+423',
  Luxembourg: '+352',
  Malta: '+356',
  Moldova: '+373',
  Monaco: '+377',
  Montenegro: '+382',
  Netherlands: '+31',
  'North Macedonia': '+389',
  Norway: '+47',
  Poland: '+48',
  Portugal: '+351',
  Romania: '+40',
  'San Marino': '+378',
  Serbia: '+381',
  Slovakia: '+421',
  Slovenia: '+386',
  Spain: '+34',
  Sweden: '+46',
  Switzerland: '+41',
  Ukraine: '+380',
  'United Kingdom': '+44'
};

export const countryLetters = objectFlip(countries);
export const countryLettersGerman = objectFlip(countriesGerman);
export const countriesSymbols = Object.keys(countries);
